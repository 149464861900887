import { render } from "./Events.vue?vue&type=template&id=7ce0fa71"
import script from "./Events.vue?vue&type=script&lang=ts"
export * from "./Events.vue?vue&type=script&lang=ts"

import "./Events.vue?vue&type=style&index=0&id=7ce0fa71&lang=scss"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QDialog});
